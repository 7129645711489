import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export const SelectedClient = ({ client }) => {
  const [selectedClient, setSelectedClient] = useState({});
  const [videos, setVideos] = useState([]);
  const [loadingImage, setLoadingImage] = useState(true);
  const [loadingVideo, setLoadingVideo] = useState(true);

  useEffect(() => {
    setSelectedClient(client);
    setVideos(client.videos);
  }, [client]);

  const handleImageLoad = () => {
    setLoadingImage(false);
  };

  const handleImageError = () => {
    setLoadingImage(false);
  };

  const handleVideoCanPlay = () => {
    setLoadingVideo(false);
  };

  const handleVideoError = () => {
    setLoadingVideo(false);
  };

  return (
    <div className="video-page">
      {(loadingVideo || loadingImage) && (
        <>
          <div className="loading-screen"></div>
          <p className="loading-message">
            loading {videos.length > 1 ? "videos" : "video"}
          </p>
        </>
      )}
      <div style={{ display: loadingImage || loadingVideo ? "none" : "block" }}>
        <div className="fade-in">
          <div className="video-title">{selectedClient.name}</div>
          <div className="video-roles">{selectedClient.roles}</div>
          <div className="painting-container">
            <img
              className="painting-image"
              src={selectedClient.galleryImage}
              alt="drawing of painting frame with video playing in the middle. Drawings of people walking around the drawing."
              onLoad={handleImageLoad}
              onError={handleImageError}
            ></img>
            {videos.map((video, index) => {
              return (
                <div key={index} className={video.videoClass}>
                  <iframe
                    title={video.description}
                    src={`https://player.vimeo.com/video/${
                      video.videoId
                    }?title=0&byline=0&portrait=0&${
                      index === 0 ? "autoplay=1" : "autoplay=0"
                    }&muted=0&loop=0`}
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                    onLoad={handleVideoCanPlay}
                    onError={handleVideoError}
                  />
                </div>
              );
            })}
          </div>
          <br />
          <Link to="/" className="back-btn">
            back to menu
          </Link>
        </div>
      </div>
    </div>
  );
};
