import React from "react";

export const Contact = () => {
  return (
    <div className="contact-container">
      <div className="header">- contact -</div>
      <div className="contact-list">
        <a className="contact-link" href="mailto:contact@letsgetdinnersometime.com">
          <img src="/assets/photo/mail.jpg" alt="hand drawn mail logo" width="50" height="50" />
        </a>
        <a
          className="contact-link"
          href="https://www.instagram.com/letsgetdinnersometime/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="/assets/photo/instagram.jpg"
            alt="hand drawn instagram logo"
            width="50"
            height="50"
          />
        </a>
      </div>
      {/* <div className="top-border"></div> */}
      {/* <img className="footer-image" src="/assets/photo/footer.jpg" alt="hand drawn dinner table" /> */}
    </div>
  );
};
