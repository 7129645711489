let clientList = [
  {
    name: "Athletica Magazine | Louis Vuitton",
    roles: "director of photography, video editor, & sound design",
    pathName: "/athletica",
    galleryImage: "assets/photo/lgds_gallery-louis-vuitton.jpg",
    videos: [
      {
        videoId: "1046227747",
        format: "video/mp4",
        description: "Denny Pham for Athletica Magazine & Louis Vuitton",
        descriptionClass: "athletica-desc",
        videoClass: "athletica",
      },
    ],
  },
  {
    name: "Bernard James",
    roles: "director, director of photography, video editor, & sound design",
    pathName: "/bernard-james",
    galleryImage: "assets/photo/lgds_gallery-bernard.jpg",
    videos: [
      {
        format: "video/mp4",
        description: "Bernard James X USM 2024",
        videoId: "1046229026",
        videoClass: "bernard-one",
      },
      {
        format: "video/mp4",
        description: "Bernard James Brand Story",
        videoId: "1046228716",
        videoClass: "bernard-two",
        press:
          "https://www.essence.com/fashion/bernard-james-holiday-campaign-video/",
      },
      {
        format: "video/mp4",
        description:
          "Bernard James Holiday 2023\nhttps://www.essence.com/fashion/bernard-james-holiday-campaign-video/",
        videoId: "1046228817",
        videoClass: "bernard-three",
      },
      {
        format: "video/mp4",
        description: "Family Portraits 2023",
        videoId: "1046229109",
        videoClass: "bernard-four",
      },
      {
        format: "video/mp4",
        description: "Bernard James PAVE 2023",
        videoId: "1046228921",
        videoClass: "bernard-five",
      },
    ],
  },
  {
    name: "Billionaire Girls Club",
    roles: "director of photography & video editor",
    pathName: "/billionaires-girls-club",
    galleryImage: "assets/photo/lgds_gallery-BGC.jpg",
    videos: [
      {
        format: "video/mp4",
        description: "Billionaire Girls Club FW 2023",
        videoClass: "bgc",
        videoId: "1046229439",
      },
    ],
  },
  {
    name: "Brandon Blackwood",
    roles: "director of photography & video editor",
    pathName: "/brandon-blackwood",
    galleryImage: "assets/photo/lgds_gallery-brandon-blackwood.jpg",
    videos: [
      {
        format: "video/mp4",
        description: "Brandon Blackwood Holiday 2024",
        videoId: "1046229885",
        videoClass: "brandon-one",
      },
      {
        format: "video/mp4",
        description: "Brandon Blackwood Holiday 2024",
        videoId: "1046229941",
        videoClass: "brandon-two",
      },
      {
        format: "video/mp4",
        description: "Brandon Blackwood Fall 2024",
        videoId: "1046229809",
        videoClass: "brandon-three",
      },
      {
        format: "video/mp4",
        description: "Brandon Blackwood Fall 2024",
        videoId: "1046229834",
        videoClass: "brandon-four",
      },
      {
        format: "video/mp4",
        description: "Brandon Blackwood Fall 2024",
        videoId: "1046229866",
        videoClass: "brandon-five",
      },
    ],
  },
  {
    name: "Camilla and Marc",
    roles: "director of photography, video editor, & sound design",
    pathName: "/camilla-and-marc",
    galleryImage: "assets/photo/lgds_gallery-camilla.jpg",
    videos: [
      {
        format: "video/mp4",
        description: "Camilla and Marc Paris Campaign",
        videoClass: "camilla-and-marc",
        videoId: "1046232178",
      },
    ],
  },
  {
    name: "CFDA Vogue",
    roles: "video editor & sound design",
    pathName: "/cfda-vogue",
    galleryImage: "assets/photo/lgds_gallery-6_ygwg2p.jpg",
    videos: [
      {
        format: "video/mp4",
        videoClass: "cfda-vogue",
        videoId: "1046232420",
      },
    ],
  },
  {
    name: "Columbia Records | Malcom Todd",
    roles: "director of photography, video editor",
    pathName: "/malcom-todd",
    galleryImage: "assets/photo/lgds-gallery-malcom-todd.jpg",
    press: "https://www.youtube.com/watch?v=dTS_aNfpbIM",
    videos: [
      {
        format: "video/mp4",
        description:
          "Malcom Todd, Chest Pain (I Love) Visualizer\nhttps://www.youtube.com/watch?v=dTS_aNfpbIM",
        videoId: "1046238827",
        videoClass: "malcom-one",
      },
      {
        format: "video/mp4",
        description: "Malcom Todd, Chest Pain (I Love) Teaser",
        videoId: "1046238888",
        videoClass: "malcom-two",
      },
    ],
  },
  {
    name: "Crack Magazine | Teezo Touchdown",
    roles: "producer, director, director of photography, & video editor",
    pathName: "/crack-magazine",
    galleryImage: "assets/photo/lgds_gallery-7_c7aazw.jpg",
    press:
      "https://crackmagazine.net/article/profiles/teezo-touchdown-album-interview/",
    videos: [
      {
        format: "video/mp4",
        description:
          "Teezo Touchdown for Crack Magazine\nhttps://crackmagazine.net/article/profiles/teezo-touchdown-album-interview/",
        videoClass: "crack-mag",
        videoId: "1046240488",
      },
    ],
  },
  // {
  //   name: "David Webb",
  //   roles: "director of photography & video editor",
  //   pathName: "/david-webb",
  //   galleryImage: "assets/photo/lgds_gallery-15.jpg",
  //   videos: [
  //     {
  //       format: "video/mp4",
  //       description: "Yasmin Wijnaldum for David Webb",
  //       videoId: "1046233478",
  //       videoClass: "david-webb",
  //     },
  //   ],
  // },
  {
    name: "Dazed Korea | Balenciaga",
    roles: "video editor",
    pathName: "/dazed-korea",
    galleryImage: "assets/photo/lgds_gallery-dazed.jpg",
    videos: [
      {
        format: "video/mp4",
        description: "Dazed Korea & Balenciaga Campaign",
        videoClass: "dazed-korea",
        videoId: "1046233963",
      },
    ],
  },
  {
    name: "Expedia",
    roles: "video editor",
    pathName: "/expedia",
    galleryImage: "assets/photo/gallery-image-3-wide_xud0gm.jpg",
    videos: [
      {
        format: "video/mp4",
        description: "Martin Skrtel & Lucas Leiva for Expedia & Liverpool FC",
        videoClass: "expedia",
        videoId: "1046234376",
      },
    ],
  },
  {
    name: "Fleur Du Mal",
    roles: "director, director of photography, video editor, sound design",
    pathName: "/fleur-du-mal",
    galleryImage: "assets/photo/lgds_gallery-fleur-du-mal.jpg",
    videos: [
      {
        format: "video/mp4",
        description: "Holiday Campaign 2024",
        videoId: "1046234690",
        videoClass: "fleur-one",
      },
      {
        format: "video/mp4",
        description: "Fleur Du Mal X Galia Lahav Collection",
        videoId: "1046234917",
        videoClass: "fleur-two",
      },
      {
        format: "video/mp4",
        description: "Fleur Du Mal X Galia Lahav Collection",
        videoId: "1046234906",
        videoClass: "fleur-three",
      },
      {
        format: "video/mp4",
        description: "Fleur Du Mal Holiday 2024 Teaser",
        videoId: "1046234884",
        videoClass: "fleur-four",
      },
      {
        format: "video/mp4",
        description: "Fleur Du Mal Holiday 2024 Ecom",
        videoId: "1046234758",
        videoClass: "fleur-five",
      },
    ],
  },
  {
    name: "Givenchy",
    roles: "video editor & sound design",
    pathName: "/givenchy",
    galleryImage: "assets/photo/lgds_gallery-16.jpg",
    videos: [
      {
        format: "video/mp4",
        description: "Givenchy SS24 Womenswear Backstage",
        videoId: "1046235217",
        videoClass: "givenchy",
      },
    ],
  },
  {
    name: "Guerlain",
    roles: "2nd director of photography",
    pathName: "/guerlain",
    galleryImage: "assets/photo/lgds-gallery-guerlian.jpg",
    videos: [
      {
        format: "video/mp4",
        description: "GUERLAIN_ROUGE BTS Campaign",
        videoId: "1046235699",
        videoClass: "guerlian-one",
      },
      {
        format: "video/mp4",
        description: "Guerlian X Emilio Pucci BTS Campaign",
        videoId: "1046235577",
        videoClass: "guerlian-two",
      },
    ],
  },
  {
    name: "Juventus | Mundial Partners",
    roles:
      "producer, director, director of photography, video editor, & sound design",
    pathName: "/juventus",
    galleryImage: "assets/photo/lgds-gallery-malcom-todd.jpg",
    videos: [
      {
        format: "video/mp4",
        description: "Juventus 24/25 Kit Reveal Campaign",
        videoId: "1046236123",
        videoClass: "malcom-one",
      },
      {
        format: "video/mp4",
        description:
          "Fashion Meets Football, Juventus, Serie A, Le PÈRE, Saturdays Activation Recap",
        videoId: "1046236247",
        videoClass: "malcom-two",
      },
    ],
  },
  {
    name: "Kenzo",
    roles: "video editor & sound design",
    pathName: "/kenzo",
    galleryImage: "assets/photo/lgds-gallery-kenzo.jpg",
    videos: [
      {
        format: "video/mp4",
        description: "Kenzo SS24 Campaign",
        videoId: "1046237168",
        videoClass: "kenzo-six",
      },
      {
        format: "video/mp4",
        description: "Kenzo SS24 Campaign",
        videoId: "1046236915",
        videoClass: "kenzo-one",
      },
      {
        format: "video/mp4",
        description: "Kenzo SS24 Campaign",
        videoId: "1046236947",
        videoClass: "kenzo-two",
      },
      {
        format: "video/mp4",
        description: "Kenzo SS24 Campaign",
        videoId: "1046236970",
        videoClass: "kenzo-three",
      },
      {
        format: "video/mp4",
        description: "Kenzo SS24 Campaign",
        videoId: "1046237041",
        videoClass: "kenzo-four",
      },
      {
        format: "video/mp4",
        description: "Kenzo SS24 Campaign",
        videoId: "1046237066",
        videoClass: "kenzo-five",
      },
    ],
  },
  {
    name: "Lanvin",
    roles: "director of photography, video editor, & sound design",
    pathName: "/lanvin",
    galleryImage: "assets/photo/lgds-gallery-lanvin.jpg",
    videos: [
      {
        format: "video/mp4",
        description: "Lanvin 1889 Campaign",
        videoId: "1046237276",
        videoClass: "lanvin-one",
      },
      {
        format: "video/mp4",
        description: "Lanvin 1889 Campaign",
        videoId: "1046237386",
        videoClass: "lanvin-two",
      },
      {
        format: "video/mp4",
        description: "Lanvin 1889 Campaign",
        videoId: "1046237473",
        videoClass: "lanvin-three",
      },
    ],
  },
  {
    name: "Le Père",
    roles:
      "producer, director, director of photography, video editor, & sound design",
    pathName: "/le-pere",
    galleryImage: "assets/photo/lgds-gallery-lepere.jpg",
    videos: [
      {
        format: "video/mp4",
        description: "Le PÈRE F/W 2024 Campaign",
        videoId: "1046238083",
        videoClass: "lepere-one",
      },
      {
        format: "video/mp4",
        description: "Denzel Curry & ASAP Ferg for Le PÈRE",
        videoId: "1046237891",
        videoClass: "lepere-two",
      },
      {
        format: "video/mp4",
        description: "Smino for Le PÈRE LA Flagship Opening",
        videoId: "1046238262",
        videoClass: "lepere-three",
      },
      {
        format: "video/mp4",
        description: "Le PÈRE Jersey Ecom",
        videoId: "1046237836",
        videoClass: "lepere-four",
      },
      {
        format: "video/mp4",
        description: "Nil for Le PÈRE F/W 2024",
        videoId: "1046238221",
        videoClass: "lepere-five",
      },
      {
        format: "video/mp4",
        description: "Tyh for Le PÈRE F/W 2024",
        videoId: "1046238411",
        videoClass: "lepere-six",
      },
    ],
  },
  {
    name: "Leset",
    roles: "director of photography, video editor, & sound design",
    pathName: "/leset",
    galleryImage: "assets/photo/lgds_gallery-leset.jpg",
    videos: [
      {
        format: "video/mp4",
        description: "Leset Spring Uniform",
        videoClass: "leset",
        videoId: "1046238692",
      },
    ],
  },
  {
    name: "Marc Jacob’s | Heavn",
    roles: "director of photography, video editor, sound design",
    pathName: "/marc-jacobs",
    galleryImage: "assets/photo/lgds_gallery-17.jpg",
    videos: [
      {
        format: "video/mp4",
        description: "Marc Jacob Heavn pop up recap",
        videoId: "1046239165",
        videoClass: "marc-jacobs",
      },
    ],
  },
  {
    name: "Mejuri",
    roles: "director, director of photography, video editor, sound design",
    pathName: "/mejuri",
    galleryImage: "assets/photo/lgds_gallery-11.jpg",
    videos: [
      {
        format: "video/mp4",
        description: "Mejuri Pierce Your Way Campaign",
        videoId: "1046239313",
        videoClass: "mejuri",
      },
    ],
  },
  {
    name: "Melitta Baumeister",
    roles: "director of photography, video editor, sound design",
    pathName: "/melitta-baumeister",
    galleryImage: "assets/photo/lgds_gallery-12.jpg",
    videos: [
      {
        format: "video/mp4",
        description: "Melitta Baumeister SS25",
        videoId: "1046239482",
        videoClass: "melitta",
      },
    ],
  },
  {
    name: "Montell Fish",
    roles: "director of photography, video editor",
    pathName: "/montell-fish",
    galleryImage: "assets/photo/lgds_gallery-montell.jpg",
    videos: [
      {
        format: "video/mp4",
        description: "Montell Fish Brooklyn Navy Steel Show 2024",
        videoId: "1046239602",
        videoClass: "montell-fish",
      },
    ],
  },
  {
    name: "On Running | FKA Twigs",
    roles: "director of photography, video editor, & sound design",
    pathName: "/on-running",
    galleryImage: "assets/photo/lgds_gallery-on-running.jpg",
    videos: [
      {
        format: "video/mp4",
        description: "FKA Twigs for On Running",
        videoId: "1049340975",
        videoClass: "on-running-one",
      },
      {
        format: "video/mp4",
        description: "FKA Twigs for On Running",
        videoId: "1049341187",
        videoClass: "on-running-two",
      },
    ],
  },
  {
    name: "Pyer Moss",
    roles:
      "producer, director, director of photography, video editor, & sound design",
    pathName: "/pyer-moss",
    galleryImage: "assets/photo/lgds-gallery-pyer-moss.jpg",
    videos: [
      {
        format: "video/mp4",
        description: "Pyer Moss DC/Miami Loot Out Campaign",
        videoId: "1046239884",
        videoClass: "pyer-moss-two",
      },
      {
        format: "video/mp4",
        description: "Pyer Moss LA Loot Out Campaign",
        videoId: "1046239901",
        videoClass: "pyer-moss-one",
      },
    ],
  },
  {
    name: "Rabanne",
    roles: "video editor",
    pathName: "/rabanne",
    galleryImage: "assets/photo/lgds_gallery-15.jpg",
    videos: [
      {
        format: "video/mp4",
        description: "Rabanne Makeup",
        videoId: "1046240038",
        videoClass: "rabanne",
      },
    ],
  },
  {
    name: "Schön Magazine",
    roles: "director of photography, video editor, & sound design",
    pathName: "/schon",
    galleryImage: "assets/photo/lgds_gallery-schon.jpg",
    videos: [
      {
        format: "video/mp4",
        description: 'Judes Kim for Schön! "Mirage" 2023',
        videoClass: "schon",
        videoId: "1046240375",
      },
    ],
  },
  {
    name: "Theory",
    roles: "director, director of photography, video editor, & sound design",
    pathName: "/theory",
    galleryImage: "assets/photo/lgds_gallery-theory.jpg",
    videos: [
      {
        format: "video/mp4",
        description: "Theory 2024 Pants Guide",
        videoId: "1046241367",
        videoClass: "theory-one",
      },
      {
        format: "video/mp4",
        description: "Theory 2024 Pants Guide",
        videoId: "1046241404",
        videoClass: "theory-two",
      },
    ],
  },
  {
    name: "The Face Magazine",
    roles: "director of photography & video editor",
    pathName: "/the-face-magazine",
    galleryImage: "assets/photo/lgds-gallery-face.jpg",
    videos: [
      {
        format: "video/mp4",
        videoId: "1046240563",
        videoClass: "face-one",
      },
      {
        format: "video/mp4",
        videoId: "1046240999",
        videoClass: "face-two",
      },
      {
        format: "video/mp4",
        videoId: "1046240813",
        videoClass: "face-three",
      },
      {
        format: "video/mp4",
        videoId: "1046241165",
        videoClass: "face-four",
      },
      {
        format: "video/mp4",
        videoId: "1046241073",
        videoClass: "face-five",
      },
      {
        format: "video/mp4",
        videoId: "1046240903",
        videoClass: "face-six",
      },
    ],
  },
  {
    name: "Valentino",
    roles: "video editor",
    pathName: "/valentino",
    galleryImage: "assets/photo/lgds-gallery-valentino.jpg",
    videos: [
      {
        format: "video/mp4",
        description: 'Valentino "Un Chateau" F/W 23/24',
        videoId: "1046241674",
        videoClass: "valentino-two",
      },
      {
        format: "video/mp4",
        description: 'Valentino "Un Chateau" F/W 23/24',
        videoId: "1046241632",
        videoClass: "valentino-one",
      },
      {
        format: "video/mp4",
        description: 'Valentino "Un Chateau" F/W 23/24',
        videoId: "1046241825",
        videoClass: "valentino-three",
      },
      {
        format: "video/mp4",
        description: 'Valentino "Un Chateau" F/W 23/24',
        videoId: "1046241973",
        videoClass: "valentino-four",
      },
      {
        format: "video/mp4",
        description: 'Valentino "Le Ciel" 2024',
        videoId: "1046241917",
        videoClass: "valentino-five",
      },
      {
        format: "video/mp4",
        description: 'Valentino "Le Ciel" 2024',
        videoId: "1046242037",
        videoClass: "valentino-six",
      },
      {
        format: "video/mp4",
        description: 'Valentino "Le Ciel" 2024',
        videoId: "1046242057",
        videoClass: "valentino-seven",
      },
    ],
  },
  {
    name: "Victoria Beckham Beauty",
    roles: "director of photography, video editor, & sound design",
    pathName: "/victoria-beckham-beauty",
    galleryImage: "assets/photo/lgds-gallery-victoria-beckham.jpg",
    videos: [
      {
        format: "video/mp4",
        description: "Victoria Beckham Paris FW 2024 Ad",
        videoId: "1046242460",
        videoClass: "victoria-one",
      },
      {
        format: "video/mp4",
        description: "Victoria Beckham Paris FW 2024 BTS",
        videoId: "1046242391",
        videoClass: "victoria-two",
      },
      {
        format: "video/mp4",
        description: "Victoria Beckham SKL BTS",
        videoId: "1046242371",
        videoClass: "victoria-three",
      },
      {
        format: "video/mp4",
        description: "Victoria Beckham Eyebrow BTS",
        videoId: "1046242251",
        videoClass: "victoria-four",
      },
      {
        format: "video/mp4",
        description: "Victoria Beckham Bergdorf Goodman Ad",
        videoId: "1046242544",
        videoClass: "victoria-five",
      },
      {
        format: "video/mp4",
        description: "Victoria Beckham LA 2024 Ad",
        videoId: "1046242686",
        videoClass: "victoria-six",
      },
    ],
  },
  {
    name: "Vogue France",
    roles: "director of photography, video editor, & sound design",
    pathName: "/vogue-france",
    galleryImage: "assets/photo/lgds_gallery-11.jpg",
    press:
      "https://www.vogue.fr/video/watch/fashion-week-de-paris-sarah-lysander-partage-ses-looks-preferes-signes-zalando-designer",
    videos: [
      {
        format: "video/mp4",
        description:
          "Sara Lysander for Vogue France & Zalando\nhttps://www.vogue.fr/video/watch/fashion-week-de-paris-sarah-lysander-partage-ses-looks-preferes-signes-zalando-designer",
        videoId: "1046243649",
        videoClass: "vogue-france",
      },
    ],
  },
  {
    name: "Vogue Italia",
    roles: "director of photography, video editor, & sound design",
    pathName: "/vogue-italia",
    galleryImage: "assets/photo/gallery-image-2-wide_ztisyj.jpg",
    videos: [
      {
        format: "video/mp4",
        description: "Heir for Vogue Italia & Zalando",
        videoClass: "vogue-italia",
        videoId: "1046244752",
      },
    ],
  },
  {
    name: "Vogue Japan",
    roles: "video editor",
    pathName: "/vogue-japan",
    galleryImage: "assets/photo/lgds_gallery-vogue-japan.jpg",
    videos: [
      {
        format: "video/mp4",
        description: "Vogue Japan November 2022",
        videoClass: "vogue-japan",
        videoId: "1046245059",
      },
    ],
  },
  {
    name: "Wonderland Magazine",
    roles: "director, director of photography, & video editor",
    pathName: "/wonderland-magazine",
    galleryImage: "assets/photo/lgds_gallery-7_c7aazw.jpg",
    videos: [
      {
        format: "video/mp4",
        description: "Hawa for Wonderland April 2023",
        videoId: "1046245153",
        videoClass: "wonderland",
      },
    ],
  },
];

export default clientList;
