import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { Landing } from "./components/Landing";
import { About } from "./components/About/About";
import { Clients } from "./components/Clients/Clients";
import { Contact } from "./components/Contact/Contact";
import { InProgressPage } from "./components/shared/InProgressPage";
import { SelectedClient } from "./components/Clients/SelectedClient";
import clientList from "./data/clients";
import "./styles/App.scss";

function App() {
  const [showGif, setShowGif] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  // show intro gif animation
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowGif(false);
    }, 5200);

    return () => clearTimeout(timer);
  }, []);

  // check mobile sizing
  useEffect(() => {
    const checkIfMobile = () => {
      if (window.innerWidth <= 500) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);
    return () => window.removeEventListener("resize", checkIfMobile);
  }, []);

  return (
    <div>
      {showGif ? (
        <div className={showGif ? "gif-container" : "gif-container fade-out"}>
          <img
            src={
              isMobile
                ? "/assets/photo/lgds-mobile.gif"
                : "/assets/photo/lgds-desktop.gif"
            }
            alt="Loading..."
            className="intro-gif"
          />
        </div>
      ) : (
        <div className="fade-in">
          <div className="box">
            <div className="box-inner">
              <Switch>
                <Route exact path="/">
                  <Landing />
                  <About />
                  <Clients clientList={clientList} />
                  <Contact />
                </Route>

                {clientList.map((client, index) => {
                  return (
                    <Route exact path={client.pathName} key={index}>
                      <SelectedClient client={client} />
                    </Route>
                  );
                })}
                <Route exact path="/in-progress">
                  <InProgressPage />
                </Route>
              </Switch>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
